import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  MDBBadge,
  MDBBtn,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBIcon,
  MDBInput,
  MDBListGroup,
  MDBListGroupItem,
  MDBRange,
  MDBSpinner,
  MDBSwitch,
} from "mdb-react-ui-kit";
import Avatar from "./Avatar";

import * as metadata from "./Metadata";
import { useWebSocketContext } from "./WebsocketProvider";

const CreateRoom = ({
  playerConfig,
  setPlayerConfig,
  generateRandomAvatar,
  tourMode,
}) => {
  const { sendJsonMessage, lastJsonMessage } = useWebSocketContext(tourMode);
  const navigate = useNavigate();
  const [isTextValid, setIsTextValid] = useState(true);

  // Setup room configuration defaults and state
  const [roomConfig, setRoomConfig] = useState({
    language: metadata.defaultRoomConfig.language,
    numRounds: metadata.defaultRoomConfig.numRounds,
    roundTimeLimit: metadata.defaultRoomConfig.roundTimeLimit,
    hardMode: metadata.defaultRoomConfig.hardMode,
    lockRoom: metadata.defaultRoomConfig.lockRoom,
    validateDictionary: metadata.defaultRoomConfig.validateDictionary,
    categories: metadata.defaultRoomConfig.categories,
  });
  // Aux mapping for clean update of category toggles
  const [selectedCategories, setSelectedCategories] = useState({
    fname: true,
    lname: true,
    city: true,
    country: true,
    // food: true,
    animal: true,
    job: true,
    color: true,
    car: true,
    movie: true,
  });

  const toggleCategory = (categoryId) => {
    selectedCategories[categoryId] = !selectedCategories[categoryId];
    setSelectedCategories(selectedCategories);
    var orderedSelectedCategories = [];
    // The list must be ordered for better familiarity
    for (const catId of Object.keys(metadata.categoryMetadata)) {
      if (selectedCategories[catId]) {
        orderedSelectedCategories.push(catId);
      }
    }
    setRoomConfig({
      ...roomConfig,
      ...{ categories: orderedSelectedCategories },
    });
  };

  const generateNewAvatar = () => {
    const avatarConfig = generateRandomAvatar();
    setPlayerConfig({
      ...playerConfig,
      avatar: avatarConfig,
    });
  };

  // Advanced options toggle state in the form
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toggleShow = () => setShowAdvanced(!showAdvanced);

  // Initiate create room
  const createRoomCommand = (e) => {
    e.preventDefault();
    if (playerConfig.name === null) {
      setIsTextValid(false);
    } else {
      sendJsonMessage({
        action: "createRoom",
        body: {
          roomConfig: roomConfig,
          playerConfig: {
            name: playerConfig.name,
            avatar: playerConfig.avatar,
          },
        },
      });
      // set button state to avoid multiple submissions
      setIsSubmitting(true);
      setTimeout(() => {
        setIsSubmitting(false);
      }, 5000);
    }
  };

  // If received message from the server take action
  useEffect(() => {
    if (lastJsonMessage != null) {
      // navigate to room if server indicated room is created
      if (lastJsonMessage.response === "roomCreated") {
        navigate("/room/" + lastJsonMessage.body.roomConfig.roomName);
      } else if (lastJsonMessage.response === "sessionCreated") {
        setPlayerConfig((p) => ({
          ...p,
          sessionId: lastJsonMessage.body.sessionId,
        }));
      }
    }
  }, [lastJsonMessage, navigate]);

  return (
    <div
      name="create-room"
      className="wr-create-room-box card mask-custom text-light"
    >
      <form className="padding-form" onSubmit={createRoomCommand}>
        <h5 className="margin-top-5">Create a Room</h5>
        <div
          className="avatar-selector padding-10"
          onClick={generateNewAvatar}
          style={{ cursor: "pointer" }}
        >
          <Avatar avatar={playerConfig.avatar} avatarSize={86} />
          <MDBBadge
            notification
            pill
            style={{
              marginTop: "-0.65rem",
              marginLeft: "0.65rem",
              fontSize: "0.65rem",
            }}
          >
            <MDBIcon fas icon="random" />
          </MDBBadge>
        </div>
        <div className="nickname-selector padding-10">
          <MDBInput
            label="Enter nickname here"
            id="playerName"
            type="text"
            size="lg"
            autoComplete="off"
            required
            onChange={(e) =>
              setPlayerConfig({ ...playerConfig, ...{ name: e.target.value } })
            }
            className={
              isTextValid === false
                ? "form-control form-control-light form-control-lg wr-transparent-text wr-text-70p is-invalid"
                : "form-control form-control-light form-control-lg wr-transparent-text wr-text-70p"
            }
          />
          {isTextValid === false && (
            <div className="form-invalid-feedback">
              You must choose a nickname
            </div>
          )}
        </div>
        {/* TODO enable multi-language selection */}
        <MDBDropdown className="language-selector padding-10">
          <MDBDropdownToggle tag="a" className="btn btn-secondary wr-text-70p">
            <MDBIcon flag="us" /> English
          </MDBDropdownToggle>
          <MDBDropdownMenu>
            <MDBDropdownItem link>
              <MDBIcon flag="us" /> English
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
        <div
          onClick={toggleShow}
          className="advanced-options-selector padding-10"
          style={{ cursor: "pointer" }}
        >
          <MDBIcon icon={showAdvanced ? "caret-down" : "caret-right"} />
          <span style={{ marginLeft: "20px" }}>Advanced game options</span>
        </div>
        <MDBCollapse show={showAdvanced}>
          <div className="padding-10">
            <label>Number of rounds</label>
            <MDBRange
              defaultValue={metadata.defaultRoomConfig.numRounds}
              min="1"
              max="10"
              step="1"
              id="numRounds"
              onChange={(e) =>
                setRoomConfig({
                  ...roomConfig,
                  ...{ numRounds: parseInt(e.target.value) },
                })
              }
            />
            <label>Round Time Limit (seconds)</label>
            <MDBRange
              defaultValue={metadata.defaultRoomConfig.roundTimeLimit}
              min="30"
              max="300"
              step="30"
              id="roundLimit"
              onChange={(e) =>
                setRoomConfig({
                  ...roomConfig,
                  ...{ roundTimeLimit: parseInt(e.target.value) },
                })
              }
            />
            {/* <div className="padding-top-bottom-10">
              <MDBSwitch id="optionHardMode" label="Hard Mode" disabled />
              <div className="wr-subtitle">
                Give players multiple character clues.
              </div>
            </div> */}
            {/* <div className="padding-top-bottom-10">
              <MDBSwitch id="optionLockRoom" label="Lock Room" disabled />
              <div className="wr-subtitle">
                Disallow players to join after the game is started.
              </div>
            </div> */}
            <div className="padding-top-bottom-10">
              <MDBSwitch
                id="optionValidateDictionary"
                label="Only Common Words"
                defaultChecked
                onChange={(e) =>
                  setRoomConfig({
                    ...roomConfig,
                    ...{ validateDictionary: !roomConfig.validateDictionary },
                  })
                }
              />
              <div className="wr-subtitle">
                Only accept words from common dictionary.
              </div>
            </div>

            <div className="padding-top-bottom-10">
              <label
                className="form-check-label"
                style={{ marginBottom: "10px" }}
              >
                Customize Categories
              </label>
              <MDBListGroup>
                {Object.keys(selectedCategories).map((category, index) => (
                  <MDBListGroupItem
                    key={index}
                    tag="label"
                    className="wr-checkbox-item text-light"
                  >
                    <MDBSwitch
                      id={"option" + category}
                      label={metadata.categoryMetadata[category].name}
                      defaultChecked
                      onClick={(_) => toggleCategory(category)}
                    />
                  </MDBListGroupItem>
                ))}
              </MDBListGroup>
            </div>
          </div>
        </MDBCollapse>
        <div className="room-create-btn padding-10">
          <MDBBtn
            type="submit"
            className="btn btn-light btn-lg btn-rounded mb-0 wg-action-button"
            block
            onClick={createRoomCommand}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <MDBSpinner grow size="sm" role="status" tag="span" />
            ) : (
              <>Create Room</>
            )}
          </MDBBtn>
          {isTextValid === false && (
            <div
              className="form-invalid-feedback"
              style={{ marginTop: "10px" }}
            >
              You forgot to choose a nickname
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

CreateRoom.defaultProps = {
  tourMode: false,
};

export default CreateRoom;
