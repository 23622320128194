import { React, useCallback, useEffect, useState } from "react";
import { MDBBtn, MDBIcon, MDBTooltip, MDBSpinner } from "mdb-react-ui-kit";
import CategoryInput from "./CategoryInput";
import Timer from "./Timer";

import * as metadata from "./Metadata";

const Game = ({
  gameState,
  timer,
  categoriesState,
  setCategoriesState,
  submitInputs,
  isSubmitting,
  tourMode,
}) => {
  const categoryLen = categoriesState.length;
  const [activeCategoryIndex, setActiveCategoryIndex] = useState(0);

  const isReadyToSubmit = (state) => {
    let sumReady = state.reduce(
      (acc, val) => acc + (val["value"] ? 1 : 0) + (val["valid"] ? 1 : 0),
      0
    );
    let isReady = sumReady === 2 * categoryLen;
    return isReady;
  };

  // Updates the active category value in the state
  const updateCategoryValue = (categoryValue) => {
    const updatedCategoryState = categoriesState.map((categoryState, index) => {
      if (index === activeCategoryIndex) {
        var isValid = false;
        var reason =
          "The Word Must Be " +
          gameState.rounds[gameState.rounds.length - 1].clueString;
        if (
          categoryValue.match(
            gameState.rounds[gameState.rounds.length - 1].clueRegex
          ) != null
        ) {
          isValid = true;
          reason = null;
        }
        return {
          ...categoryState,
          value: categoryValue,
          valid: isValid,
          invalidReason: reason,
        };
      } else {
        return categoryState;
      }
    });
    setCategoriesState((categoriesState) => updatedCategoryState);
  };

  // Define keyboard shortcuts
  const handleKeyPress = useCallback(
    (event) => {
      if (!tourMode) {
        if (event.keyCode === 38) {
          // up arrow key
          setActiveCategoryIndex(
            (activeCategoryIndex) =>
              (((activeCategoryIndex - 1) % categoryLen) + categoryLen) %
              categoryLen
          );
        } else if (event.keyCode === 40) {
          // down arrow key
          setActiveCategoryIndex(
            (activeCategoryIndex) => (activeCategoryIndex + 1) % categoryLen
          );
        } else if (event.keyCode === 13) {
          // enter key
          if (isReadyToSubmit(categoriesState)) {
            // Submit results if all ready
            submitInputs();
          } else {
            // Revolve to next category if not ready
            setActiveCategoryIndex((activeCategoryIndex) => {
              for (let i = 1; i < categoryLen; i++) {
                let category =
                  categoriesState[(activeCategoryIndex + i) % categoryLen];
                if (
                  category.valid !== true ||
                  category.serverValid === false ||
                  category.value === ""
                ) {
                  return (activeCategoryIndex + i) % categoryLen;
                }
              }
            });
          }
        }
      }
    },
    [categoryLen, categoriesState]
  );

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  // Game start timer
  const [startCounter, setStartCounter] = useState(
    parseInt(
      Math.max(
        (gameState.stateTimer - timer) / 1000 -
          gameState.roomConfig.roundTimeLimit +
          1,
        0
      )
    )
  );
  useEffect(() => {
    if (startCounter > 0) {
      setTimeout(() => setStartCounter(startCounter - 1), 1000);
    } else {
      setStartCounter(0);
    }
  }, [startCounter]);

  return (
    <div
      name="game-col"
      className="col-md-9 col-lg-9 col-xl-9 mb-4 mb-md-0 order-1 order-lg-2 order-md-2 order-xl-2"
    >
      {startCounter > 0 ? (
        <div
          name="game"
          className="mask-custom text-light wr-game-box"
          style={{ background: "rgba(24, 24, 16, 0.3)" }}
        >
          <h1>
            Round {gameState.rounds.length}/{gameState.roomConfig.numRounds}
          </h1>
          <h1>
            Words {gameState.rounds[gameState.rounds.length - 1].clueString}
          </h1>
          <span style={{ fontSize: "12vw" }}>{startCounter}</span>
        </div>
      ) : (timer > 0 && gameState.gameState === "RoundPlaying") || tourMode ? (
        <div name="game" className="mask-custom text-light wr-game-box">
          <div name="category-main-box" className="category-main-box">
            <div className="padding-5">
              Round {gameState.rounds.length}/{gameState.roomConfig.numRounds}
            </div>
            <h3 className="round-clue">
              Words {gameState.rounds[gameState.rounds.length - 1].clueString}
            </h3>
            <Timer gameState={gameState} timer={timer} tourMode={tourMode} />
            <div name="categoriesSlider">
              {categoriesState.map(
                (categoryState, index) =>
                  index === activeCategoryIndex && (
                    <CategoryInput
                      key={index}
                      categoryState={categoryState}
                      round={gameState.rounds[gameState.rounds.length - 1]}
                      updateCategoryValue={updateCategoryValue}
                    />
                  )
              )}
            </div>
            <div
              name="round-submit-box"
              className="round-submit-box padding-10"
            >
              {isReadyToSubmit(categoriesState) ? (
                <MDBBtn
                  className="btn btn-light btn-lg btn-rounded mb-0 wg-action-button"
                  onClick={submitInputs}
                  disabled={isSubmitting}
                  style={{ minWidth: "240px" }}
                >
                  {isSubmitting ? (
                    <MDBSpinner grow size="sm" role="status" tag="span" />
                  ) : (
                    <>Submit Results</>
                  )}
                </MDBBtn>
              ) : (
                <MDBBtn
                  className="btn btn-light btn-lg btn-rounded mb-0 wg-action-button"
                  style={{ minWidth: "240px" }}
                  disabled
                >
                  Finish Categories First
                </MDBBtn>
              )}
            </div>
          </div>
          <div
            name="category-tooltip"
            className="category-tooltip"
            style={{
              width: "50px",
              display: "inline-block",
              float: "right",
              margin: "auto",
            }}
          >
            <div className="category-button-up padding-10">
              <MDBBtn
                floating
                tag="a"
                color="light"
                size="sm"
                onClick={() =>
                  setActiveCategoryIndex(
                    (activeCategoryIndex) =>
                      (((activeCategoryIndex - 1) % categoryLen) +
                        categoryLen) %
                      categoryLen
                  )
                }
              >
                <MDBIcon fas icon="angle-double-up" />
              </MDBBtn>
            </div>
            <div className="category-button-selector">
              {categoriesState.map((categoryState, index) => {
                let categoryColor;
                if (activeCategoryIndex === index) {
                  categoryColor = "primary";
                } else if (categoryState.serverValid === true) {
                  categoryColor = "success";
                } else if (categoryState.valid === true) {
                  categoryColor = "info";
                } else if (categoryState.valid === false) {
                  categoryColor = "danger";
                } else {
                  categoryColor = "light";
                }

                return (
                  <MDBTooltip
                    key={index}
                    wrapperProps={{ color: categoryColor }}
                    wrapperClass={
                      "wr-category-floating-icon tooltip-" +
                      categoryState.id +
                      "-btn"
                    }
                    title={
                      <>
                        {metadata.categoryMetadata[categoryState.id].name}{" "}
                        <b>{categoryState.value}</b>
                      </>
                    }
                    placement="left"
                    onShow={() => setActiveCategoryIndex(index)}
                  >
                    <MDBIcon
                      icon={metadata.categoryMetadata[categoryState.id].icon}
                    />
                  </MDBTooltip>
                );
              })}
            </div>
            <div className="category-button-down padding-10">
              <MDBBtn
                floating
                tag="a"
                color="light"
                size="sm"
                onClick={() =>
                  setActiveCategoryIndex(
                    (activeCategoryIndex) =>
                      (activeCategoryIndex + 1) % categoryLen
                  )
                }
              >
                <MDBIcon fas icon="angle-double-down" />
              </MDBBtn>
            </div>
          </div>
        </div>
      ) : (
        <div
          name="game"
          className="mask-custom text-light wr-game-box"
          style={{ background: "rgba(24, 24, 16, 0.3)" }}
        >
          <h1>Times Up! 🔥</h1>
        </div>
      )}
    </div>
  );
};

Game.defaultProps = {
  tourMode: false,
};

export default Game;
