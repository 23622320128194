import React from "react";
import { Link } from "react-router-dom";
import { MDBFooter } from "mdb-react-ui-kit";

const Footer = () => {
  return (
    <MDBFooter className="text-center text-lg-left text-footer">
      <div className="text-center p-3 text-light">
        &copy; {new Date().getFullYear()} Copyright{" "}
        <a className="text-light" href="https://wordracer.app/">
          WordRacer
        </a>
        <span className="margin-left-5 margin-right-5">•</span>
        <a className="text-light" href="mailto:play@wordracer.app">
          Contact Us
        </a>
        <span className="margin-left-5 margin-right-5">•</span>
        <Link className="text-light" to="/terms">
          Terms and Policies
        </Link>
      </div>
    </MDBFooter>
  );
};

export default Footer;
