import { Outlet } from "react-router-dom";
import { WebSocketProvider } from "./WebsocketProvider";

const WebsocketOutlet = () => {
  return (
    <WebSocketProvider>
      <Outlet />
    </WebSocketProvider>
  );
};

export default WebsocketOutlet;
