import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  MDBBadge,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBSpinner,
} from "mdb-react-ui-kit";
import Avatar from "./Avatar";
import { useWebSocketContext } from "./WebsocketProvider";

const JoinRoom = ({ playerConfig, setPlayerConfig, generateRandomAvatar }) => {
  const { sendJsonMessage, lastJsonMessage } = useWebSocketContext();
  const roomName = window.location.pathname.slice(11);
  const [isTextValid, setIsTextValid] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const joinRoomCommand = (e) => {
    e.preventDefault();
    if (playerConfig.name === null) {
      setIsTextValid(false);
    } else {
      sendJsonMessage({
        action: "joinRoom",
        body: {
          roomName: roomName,
          playerConfig: {
            name: playerConfig.name,
            avatar: playerConfig.avatar,
          },
        },
      });
      // set button state to avoid multiple submissions
      setIsSubmitting(true);
      setTimeout(() => {
        setIsSubmitting(false);
      }, 5000);
    }
  };

  const generateNewAvatar = () => {
    const avatarConfig = generateRandomAvatar();
    setPlayerConfig({
      ...playerConfig,
      avatar: avatarConfig,
    });
  };

  useEffect(() => {
    if (lastJsonMessage !== null) {
      // navigate to room if server indicated room is created
      if (lastJsonMessage.response === "playerJoined") {
        navigate("/room/" + lastJsonMessage.body.roomConfig.roomName);
      } else if (lastJsonMessage.response === "sessionCreated") {
        setPlayerConfig((p) => ({
          ...p,
          sessionId: lastJsonMessage.body.sessionId,
        }));
      }
    }
  }, [lastJsonMessage, navigate]);

  return (
    <div
      name="create-room"
      className="wr-create-room-box card mask-custom text-light"
    >
      <form className="padding-form">
        <h5 className="margin-top-5">Join the Room</h5>
        <label>Choose avatar and a nickname</label>
        <div
          className="padding-10"
          onClick={generateNewAvatar}
          style={{ cursor: "pointer" }}
        >
          <Avatar avatar={playerConfig.avatar} avatarSize={86} />
          <MDBBadge
            notification
            pill
            style={{
              marginTop: "-0.65rem",
              marginLeft: "0.65rem",
              fontSize: "0.65rem",
            }}
          >
            <MDBIcon fas icon="random" />
          </MDBBadge>
        </div>
        <div className="padding-10">
          <MDBInput
            label="Enter nickname here"
            id="playerName"
            type="text"
            size="lg"
            autoComplete="off"
            onChange={(e) =>
              setPlayerConfig({ ...playerConfig, ...{ name: e.target.value } })
            }
            className={
              isTextValid === false
                ? "form-control form-control-light form-control-lg wr-transparent-text wr-text-70p is-invalid"
                : "form-control form-control-light form-control-lg wr-transparent-text wr-text-70p"
            }
          />
          {isTextValid === false && (
            <div className="form-invalid-feedback">
              You must choose a nickname
            </div>
          )}
        </div>
        <div className="padding-10">
          <MDBBtn
            type="submit"
            className="btn btn-light btn-lg btn-rounded mb-0 wg-action-button"
            block
            onClick={joinRoomCommand}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <MDBSpinner grow size="sm" role="status" tag="span" />
            ) : (
              <>Join Room</>
            )}
          </MDBBtn>
        </div>
      </form>
    </div>
  );
};

export default JoinRoom;
