export const defaultRoomConfig = {
  language: "en",
  numRounds: 3,
  roundTimeLimit: 120,
  hardMode: false,
  lockRoom: false,
  validateDictionary: true,
  categories: [
    "fname",
    "lname",
    "city",
    "country",
    // "food",
    "animal",
    "job",
    "color",
    "car",
    "movie",
  ],
};

export const categoryMetadata = {
  fname: { name: "Baby Name", icon: "baby" },
  lname: { name: "Family Name", icon: "signature" },
  city: { name: "City", icon: "city" },
  country: { name: "Country", icon: "flag" },
  // food: { name: "Food", icon: "hamburger" },
  animal: { name: "Animal", icon: "paw" },
  job: { name: "Profession", icon: "briefcase" },
  color: { name: "Color", icon: "palette" },
  car: { name: "Car Make", icon: "car" },
  movie: { name: "Movie", icon: "film" },
};

export const avatarMetadata = {
  skinColors: [
    "#FFCC22",
    "#FBD2C7",
    "#F2AD9B",
    "#E58F7B",
    "#E4A070",
    "#B16A5B",
    "#92594B",
    "#623D36",
    "#C9E6DC",
  ],
  hairColors: [
    "#362C47",
    "#675E97",
    "#5AC4D4",
    "#DEE1F5",
    "#6C4545",
    "#F29C65",
    "#E16381",
    "#E15C66",
  ],
  facialHairColors: [
    "#362C47",
    "#675E97",
    "#5AC4D4",
    "#DEE1F5",
    "#6c4545",
    "#F29C65",
    "#E16381",
    "#E15C66",
  ],
  bodyColors: [
    "#456DFF",
    "#5A45FF",
    "#6DBB58",
    "#F55D81",
    "#7555CA",
    "#E24553",
    "#54D7C7",
    "#F3B63A",
  ],
  backgroundColors: [
    "#F3B63A",
    "#A9E775",
    "#FF7A9A",
    "#B379F7",
    "#FF6674",
    "#89E6E4",
    "#FFCC65",
    "#F8FBFF",
  ],
  facialHairTypes: [
    "BeardMustache",
    "Mustache",
    "Nofacial",
    "Goatee",
    "Nofacial",
    "Shadow",
    "Soulpatch",
    "Walrus",
    "Nofacial",
  ],
  eyesTypes: [
    "EyesGlasses",
    "EyesHappy",
    "EyesOpen",
    "EyesSleepy",
    "EyesSunglasses",
    "EyesWink",
  ],
  mouthTypes: [
    "MouthBigsmile",
    "MouthFrown",
    "MouthLips",
    "MouthPacifier",
    "MouthSmile",
    "MouthSmirk",
    "MouthSurprise",
  ],
  noseTypes: ["NoseMediumround", "NoseSmallround", "NoseWrinkles"],
  bodyTypes: ["Checkered", "BodyOval", "BodyRound", "BodySquare"],
  hairTypes: [
    "Bald",
    "Balding",
    "Bigcurls",
    "Bobbangs",
    "Bobcut",
    "Buncurls",
    "Buzzcut",
    "Curlybun",
    "Curlyhightop",
    "Hightopcurly",
    "Long",
    "Pigtails",
    "Shortcombover",
    "Beanie",
    "Bunundercut",
    "Extralong",
    "Fade",
    "Hat",
    "Mohawk",
    "Shortcomboverchops",
    "Sidebuzz",
    "Straightbun",
  ],
};

export const errorDescriptions = {
  404: "room does not exist or has been expired.",
  403: "room is locked, you can not enter the room.",
  408: "connection with the server lost.",
  410: "your session has expired due to inactivity.",
  500: "game server encoutered unknown error.",
  // Websocket codes
  1001: "your session has expired due to inactivity.",
  1005: "application is restarted.",
};
