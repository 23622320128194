import { React, useState } from "react";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import Timer from "./Timer";
import RoundResult from "./RoundResult";

const RoundResults = ({ playerConfig, gameState, timer, tourMode }) => {
  const [visibleRound, setVisibleRound] = useState(gameState.rounds.length - 1);
  const handleTab = (value) => {
    if (value === visibleRound) {
      return;
    }
    setVisibleRound(value);
  };

  return (
    <div
      name="round-results-col"
      className="col-md-9 col-lg-9 col-xl-9 mb-4 mb-md-0 order-1 order-lg-2 order-md-2 order-xl-2"
    >
      <div name="round-results" className="mask-custom text-light wr-game-box">
        <Timer
          gameState={gameState}
          timer={timer}
          displayText="Next round starts in"
          tourMode={tourMode}
        />
        <MDBTabs justify pills fill className="mb-3">
          {gameState.rounds.map((round, roundNum) => (
            <MDBTabsItem key={roundNum}>
              <MDBTabsLink
                onClick={() => handleTab(roundNum)}
                active={visibleRound === roundNum}
              >
                Round {roundNum + 1}
              </MDBTabsLink>
            </MDBTabsItem>
          ))}
        </MDBTabs>

        <MDBTabsContent>
          {gameState.rounds.map((round, roundNum) => (
            <MDBTabsPane key={roundNum} show={visibleRound === roundNum}>
              <RoundResult
                round={round}
                playerConfig={playerConfig}
                gameState={gameState}
                tourMode={tourMode}
              />
            </MDBTabsPane>
          ))}
        </MDBTabsContent>
      </div>
    </div>
  );
};

RoundResults.defaultProps = {
  tourMode: false,
};

export default RoundResults;
