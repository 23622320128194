import { React, useState } from "react";
import {
  MDBInputGroup,
  MDBInput,
  MDBIcon,
  MDBBtn,
  MDBSpinner,
} from "mdb-react-ui-kit";

const WaitingRoom = ({ playerConfig, gameState, sendMessage }) => {
  const joinLink =
    window.location.protocol +
    "//" +
    window.location.host +
    "/join-room/" +
    window.location.pathname.slice(6);

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Initiate create room
  const startGameCommand = (e) => {
    // TODO validation
    e.preventDefault();
    sendMessage({
      action: "startGame",
      body: { roomName: gameState.roomConfig.roomName },
    });
    // set button state to avoid multiple submissions
    setIsSubmitting(true);
    setTimeout(() => {
      setIsSubmitting(false);
    }, 5000);
  };

  return (
    <div
      name="waiting-room-col"
      className="col-md-9 col-lg-9 col-xl-9 mb-4 mb-md-0 order-1 order-lg-2 order-md-2 order-xl-2"
    >
      <div name="waiting-room" className="mask-custom text-light wr-game-box">
        <div className="padding-10">
          <h4>Waiting Room</h4>
          <p>
            Waiting for other players to join the room.
            <br />
            Share the following link with other players to join the room:
          </p>
          <div className="padding-10">
            <MDBInputGroup className="justify-center">
              <MDBInput label={joinLink} disabled />
              <MDBBtn
                rippleColor="dark"
                className="invite-selector btn-info"
                onClick={() => {
                  navigator.clipboard.writeText(joinLink);
                }}
              >
                <MDBIcon icon="copy" />
                <span style={{ marginLeft: "10px" }}>Copy Link</span>
              </MDBBtn>
            </MDBInputGroup>
          </div>
          <div className="game-start-btn padding-10">
            {playerConfig.host === true ? (
              <MDBBtn
                className="btn btn-light btn-lg btn-rounded mb-0 wg-action-button"
                onClick={startGameCommand}
                disabled={isSubmitting}
                style={{ minWidth: "175px" }}
              >
                {isSubmitting ? (
                  <MDBSpinner grow size="sm" role="status" tag="span" />
                ) : (
                  <>Start the Game</>
                )}
              </MDBBtn>
            ) : (
              <MDBBtn
                className="btn btn-light btn-lg btn-rounded mb-0 wg-action-button"
                disabled
              >
                Wait for Host to Start The Game
              </MDBBtn>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaitingRoom;
