import { React } from "react";
import { MDBIcon, MDBInput } from "mdb-react-ui-kit";

import * as metadata from "./Metadata";

const CategoryInput = ({
  index,
  round,
  categoryState,
  updateCategoryValue,
}) => {
  return (
    <div itemID={index} name={"category-name-" + categoryState.id}>
      <p className="padding-10 wr-category-lg-icon">
        <MDBIcon
          icon={metadata.categoryMetadata[categoryState.id].icon}
          size="4x"
        />
      </p>
      <h5>
        Write "{metadata.categoryMetadata[categoryState.id].name}"{" "}
        {round.clueString}
      </h5>
      <div className="category-text-field padding-10">
        <MDBInput
          label={
            "Enter " +
            metadata.categoryMetadata[categoryState.id].name +
            " Here"
          }
          id={metadata.categoryMetadata[categoryState.id].name + "Input"}
          defaultValue={categoryState.value}
          type="text"
          size="lg"
          autoComplete="off"
          autoFocus
          onChange={(e) => updateCategoryValue(e.target.value)}
          className={
            categoryState.valid === false
              ? "form-control form-control-light form-control-lg wr-transparent-text wr-text-70p is-invalid"
              : "form-control form-control-light form-control-lg wr-transparent-text wr-text-70p"
          }
        />
        {categoryState.valid === false && (
          <div className="form-invalid-feedback">
            {categoryState.invalidReason}
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryInput;
