import React from "react";
import { useNavigate } from "react-router-dom";
import { MDBBtn } from "mdb-react-ui-kit";

import * as metadata from "./Metadata";
import homeImage from "./images/wordracer_logo.png";

const Home = () => {
  let navigate = useNavigate();
  let params = new URL(document.location).searchParams;
  let statusCode = params.get("statusCode");

  return (
    <div name="home">
      {statusCode !== null && (
        <MDBBtn
          outline
          noRipple
          className="mx-2"
          color="warning"
          style={{ cursor: "default", textTransform: "inherit" }}
        >
          Error:{" "}
          {metadata.errorDescriptions[statusCode] ||
            "Unknown Error Code " + statusCode}
        </MDBBtn>
      )}
      <div className="wr-home-box text-light">
        <div className="home-image">
          <img style={{ maxWidth: "100%" }} alt="WordRacer - Online Categories Game" src={homeImage} />
        </div>
        <div className="padding-10">
          WordRacer is an online multiplayer adoption of <i>Categories </i>{" "}
          game, which tests you out on your vocabulary knowledge against your
          friends and family. Click below to start a game, invite people, and
          have fun. For the best experience, please use a computer to play this
          game.
        </div>
        <div className="padding-10 d-grid">
          <MDBBtn
            className="btn btn-light btn-lg btn-rounded mb-0 wg-action-button"
            onClick={() => navigate("/create-room")}
          >
            Start a New Game
          </MDBBtn>
        </div>
        <div>
          <MDBBtn
            className="btn btn-secondary btn-md btn-rounded mb-0 wg-action-button"
            onClick={() => navigate("/tour")}
          >
            Learn how to Play
          </MDBBtn>
        </div>
      </div>
    </div>
  );
};

export default Home;
