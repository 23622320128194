import React from "react";

const Header = () => {
  const navigateToHomePage = () => {
    window.location.href = "/";
  };

  return (
    <div className="logo-text text-light">
      <span onClick={navigateToHomePage} style={{ cursor: "pointer" }}>
        WordRacer
      </span>
    </div>
  );
};

export default Header;
