import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MDBSpinner } from "mdb-react-ui-kit";

import Game from "./Game";
import GameResults from "./GameResults";
import RankingStats from "./RankingStats";
import RoundResults from "./RoundResults";
import WaitingRoom from "./WaitingRoom";
import { useWebSocketContext } from "./WebsocketProvider";

const Room = ({
  playerConfig,
  setPlayerConfig,
  gameState,
  setGameState,
  tourMode,
}) => {
  const { sendJsonMessage, lastJsonMessage } = useWebSocketContext(tourMode);
  const roomName =
    window.location.pathname.split("room/")[
      window.location.pathname.split("room/").length - 1
    ];
  const navigate = useNavigate();
  // Player's categories state
  const [categoriesState, setCategoriesState] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Timer state
  const [timer, setTimer] = useState(null);

  // timer logic
  useEffect(() => {
    if (timer !== null && timer < gameState.stateTimer) {
      setTimeout(() => setTimer(new Date()), 1000);
    } else {
      setTimer(null);
    }
  }, [timer, gameState.stateTimer]);

  // Function that updates the frontend state based on backend state response
  // TODO make it diff state update. Requires solving for race conditions
  const updateGameState = (message, resetCategoryStates) => {
    // If new timer value sent from the server
    var t = new Date();
    if (message.stateTimer) {
      // NOTE only convert to Date object if integer is received from server
      if (Number.isInteger(message.stateTimer)) {
        message.stateTimer = new Date(message.stateTimer * 1000);
      }
    } else {
      t = null;
    }
    setTimer(t);
    // Update game state in the frontend
    setGameState(message);

    // if category state never set before
    if (resetCategoryStates || false || categoriesState === null) {
      setCategoriesState(
        message.roomConfig.categories.map(function (e) {
          return {
            value: "",
            valid: null,
            serverValid: null,
            invalidReason: null,
            id: e,
          };
        })
      );
    }
  };

  const submitInputs = () => {
    if (isSubmitting === true) {
      return;
    }
    let categoryInputs = {};
    categoriesState.forEach((c) => {
      let val = null;
      if (!!c.value) {
        val = c.value
          .trim() // trim before/after white spaces
          .toLowerCase() // make standard lower case
          .replace(/([^\w ]|_)/g, "") // remove special characters
          .replace(/\s\s+/g, " "); // remove extra white spaces and tabs and new lines
      }
      categoryInputs[c.id] = val;
    });
    sendJsonMessage({
      action: "submitInputs",
      body: {
        roomName: roomName,
        categoryInputs: categoryInputs,
        roundId: gameState.rounds.length,
      },
    });
    // set button state to avoid multiple submissions
    setIsSubmitting(true);
    setTimeout(() => {
      setIsSubmitting(false);
    }, 5000);
  };

  useEffect(() => {
    if (lastJsonMessage != null) {
      setIsLoading(false);
      // If room is created
      if (lastJsonMessage.response === "roomCreated") {
        // setting player session id based on the webhook response
        setPlayerConfig((p) => ({
          ...p,
          ...lastJsonMessage.body.players[playerConfig.sessionId].playerConfig,
        }));
        updateGameState(lastJsonMessage.body);
      } else if (lastJsonMessage.response === "roomChecked") {
        if (lastJsonMessage.body.statusCode === 404) {
          navigate("/?statusCode=404");
        } else if (lastJsonMessage.body.statusCode === 307) {
          navigate("/join-room/" + roomName);
        }
      }
      // If a new player joined the room
      else if (lastJsonMessage.response === "playerJoined") {
        // setting player session id based on the webhook response
        setPlayerConfig((p) => ({
          ...p,
          ...lastJsonMessage.body.players[playerConfig.sessionId].playerConfig,
        }));
        updateGameState(lastJsonMessage.body);
      }
      // If an existing player left the room
      else if (lastJsonMessage.response === "playerLeft") {
        updateGameState(lastJsonMessage.body);
      }
      // If the game is started by the host
      else if (lastJsonMessage.response === "gameStarted") {
        updateGameState(lastJsonMessage.body, true);
      }
      // If the round ended
      else if (lastJsonMessage.response === "roundEnded") {
        updateGameState(lastJsonMessage.body);
        submitInputs();
      }
      // If the game is started by the host
      else if (lastJsonMessage.response === "roundResults") {
        updateGameState(lastJsonMessage.body);
      }
      // If player submitted the results but some of the results are not accepted in the backend
      else if (lastJsonMessage.response === "inputInvalid") {
        let validity = lastJsonMessage.body;
        let updatedCategoriesState = categoriesState.map(function (
          categoryState
        ) {
          return {
            ...categoryState,
            valid: validity[categoryState.id],
            serverValid: validity[categoryState.id],
            invalidReason: "Word does not exist in the dictionary.",
            id: categoryState.id,
          };
        });
        setCategoriesState(updatedCategoriesState);
      }
    } else if (!tourMode) {
      setIsLoading(true);
      sendJsonMessage({
        action: "checkRoom",
        body: { roomName: roomName },
      });
    } else if (tourMode) {
      setCategoriesState(
        gameState.roomConfig.categories.map(function (e) {
          if (e === "lname") {
            return {
              value: "Carson",
              valid: true,
              serverValid: true,
              invalidReason: null,
              id: e,
            };
          } else if (e === "country") {
            return {
              value: "",
              valid: true,
              serverValid: null,
              invalidReason: null,
              id: e,
            };
          } else if (e === "animal") {
            return {
              value: "",
              valid: false,
              serverValid: false,
              invalidReason: null,
              id: e,
            };
          } else {
            return {
              value: "",
              valid: null,
              serverValid: null,
              invalidReason: null,
              id: e,
            };
          }
        })
      );
    }
  }, [lastJsonMessage, navigate, roomName, sendJsonMessage, setPlayerConfig]);

  return (
    <div name="room" className="row wr-table-container">
      {isLoading === true ? (
        <div className="d-flex justify-content-center">
          <MDBSpinner grow color="light">
            <span className="visually-hidden">Loading...</span>
          </MDBSpinner>
        </div>
      ) : (
        <>
          <RankingStats gameState={gameState} />
          {gameState.gameState === "WaitingRoom" && (
            <WaitingRoom
              playerConfig={playerConfig}
              gameState={gameState}
              sendMessage={sendJsonMessage}
            />
          )}
          {["RoundPlaying", "RoundEnded"].includes(gameState.gameState) && (
            <Game
              gameState={gameState}
              timer={timer}
              categoriesState={categoriesState}
              setCategoriesState={setCategoriesState}
              submitInputs={submitInputs}
              isSubmitting={isSubmitting}
              tourMode={tourMode}
            />
          )}
          {gameState.gameState === "RoundResults" && (
            <RoundResults
              gameState={gameState}
              playerConfig={playerConfig}
              timer={timer}
              tourMode={tourMode}
            />
          )}
          {gameState.gameState === "GameResults" && (
            <GameResults
              gameState={gameState}
              playerConfig={playerConfig}
              tourMode={tourMode}
            />
          )}
        </>
      )}
    </div>
  );
};

Room.defaultProps = {
  tourMode: false,
};

export default Room;
