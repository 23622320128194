import Avatar from "./Avatar";

const RankingPlayer = ({ player }) => {
  return (
    <li className="p-2 border-bottom">
      <div className="d-flex flex-row">
        <Avatar
          avatar={player.playerConfig.avatar}
          avatarSize={60}
          marginLeft={0}
          marginRight={10}
        />
        <div className="pt-1 wr-player-rank-div" align="left">
          <p className="fw-bold mb-0">{player.playerConfig.name}</p>
          <span className="badge bg-danger margin-right-5">
            {player.playerStat.totalPoints}
          </span>
          {player.playerConfig.host ? (
            <span className="badge bg-info">Host</span>
          ) : (
            ""
          )}
        </div>
      </div>
    </li>
  );
};

export default RankingPlayer;
