import { React } from "react";
import { MDBIcon } from "mdb-react-ui-kit";

const Timer = ({ gameState, timer, displayText, tourMode }) => {
  return (
    <div name="timer" className="timer-div">
      {tourMode ? (
        <p>
          <MDBIcon icon="clock" />
          <span style={{ marginLeft: "10px" }}>{displayText}: 25 seconds</span>
        </p>
      ) : timer !== null && gameState.stateTimer - timer > 0 ? (
        <p>
          <MDBIcon icon="clock" />
          <span style={{ marginLeft: "10px" }}>
            {displayText}:{" "}
            {parseInt(Math.max(gameState.stateTimer - timer, 0) / 1000)} seconds
          </span>
        </p>
      ) : (
        <p>
          <MDBIcon icon="clock" />
          <span style={{ marginLeft: "10px" }}>Time is up 🔥</span>
        </p>
      )}
    </div>
  );
};

Timer.defaultProps = {
  displayText: "Time remaining",
  tourMode: false,
};

export default Timer;
