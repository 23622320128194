import { React } from "react";
import RankingPlayer from "./RankingPlayer";

const RankingStats = ({ gameState }) => {
  return (
    <div
      name="ranking-col"
      className="col-md-3 col-lg-3 col-xl-3 mb-4 mb-md-0 order-3 order-lg-1 order-md-1 order-xl-1"
    >
      <div
        name="ranking"
        className="ranking-sidebar card mask-custom text-light wr-game-box"
      >
        <ul className="player-stat list-unstyled mb-0">
          {Object.keys(gameState.players)
            .sort((a, b) =>
              gameState.players[a].playerStat.totalPoints >
              gameState.players[b].playerStat.totalPoints
                ? -1
                : 1
            )
            .map((sessionId) => (
              <RankingPlayer
                key={sessionId}
                player={gameState.players[sessionId]}
              />
            ))}
        </ul>
      </div>
    </div>
  );
};

export default RankingStats;
