import { React, useState } from "react";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import RoundResult from "./RoundResult";
import Avatar from "./Avatar";

import "../css/GameResults.scss";

const GameResults = ({ gameState, playerConfig, tourMode }) => {
  const [visibleTab, setVisibleTab] = useState(
    tourMode ? "Leaderboard" : gameState.rounds.length - 1
  );
  const handleTab = (value) => {
    if (value === visibleTab) {
      return;
    }
    setVisibleTab(value);
  };

  return (
    <div
      name="game-results-col"
      className="col-md-9 col-lg-9 col-xl-9 mb-4 mb-md-0 order-1 order-lg-2 order-md-2 order-xl-2"
    >
      <div name="game-results" className="mask-custom text-light wr-game-box">
        <div className="container">
          <div className="game-results-trophies">
            <Trophies gameState={gameState} />
          </div>
          <div className="game-results-tabs">
            <MDBTabs
              pills
              fill
              justify
              className="mb-3"
              style={{ marginTop: "6rem" }}
            >
              {gameState.rounds.map((round, roundNum) => (
                <MDBTabsItem key={roundNum}>
                  <MDBTabsLink
                    onClick={() => handleTab(roundNum)}
                    active={visibleTab === roundNum}
                  >
                    Round {roundNum + 1}
                  </MDBTabsLink>
                </MDBTabsItem>
              ))}
              <MDBTabsItem>
                <MDBTabsLink
                  onClick={() => handleTab("Leaderboard")}
                  active={visibleTab === "Leaderboard"}
                >
                  Leader Board
                </MDBTabsLink>
              </MDBTabsItem>
            </MDBTabs>
          </div>
          <div className="game-results-tab-contents">
            <MDBTabsContent>
              {gameState.rounds.map((round, roundNum) => (
                <MDBTabsPane key={roundNum} show={visibleTab === roundNum}>
                  <RoundResult
                    round={round}
                    playerConfig={playerConfig}
                    gameState={gameState}
                  />
                </MDBTabsPane>
              ))}
              <MDBTabsPane show={visibleTab === "Leaderboard"}>
                <LeaderBoard players={gameState.players} />
              </MDBTabsPane>
            </MDBTabsContent>
          </div>
          {/* {playerConfig.host ? (
            <div className="padding-10">
              <MDBBtn className="btn btn-light btn-lg btn-rounded mb-0 wg-action-button">
                Start Another Game
              </MDBBtn>
            </div>
          ) : (
            ""
          )} */}
        </div>
      </div>
    </div>
  );
};

const Trophies = ({ gameState }) => {
  const borderColors = ["#ffc500", "#d4d4d4", "#ab6528"];
  return (
    <div className="top-leaders-list" style={{ marginTop: "1rem" }}>
      {Object.keys(gameState.players)
        .sort((a, b) =>
          gameState.players[a].playerStat.totalPoints >
          gameState.players[b].playerStat.totalPoints
            ? -1
            : 1
        )
        .slice(0, 3)
        .map((sessionId, index) => (
          <div className="leader" key={sessionId}>
            <div className="containerImage">
              <Avatar
                avatar={gameState.players[sessionId].playerConfig.avatar}
                avatarSize={100}
                border={borderColors[index] + " solid 4px"}
              />
              <div className="crown">
                <svg
                  id="crown1"
                  fill="#0f74b5"
                  data-name={"Layer " + sessionId}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 100 50"
                >
                  <polygon
                    className="cls-1"
                    points="12.7 50 87.5 50 100 0 75 25 50 0 25.6 25 0 0 12.7 50"
                  />
                </svg>
              </div>
              <div className="leader-name">
                {gameState.players[sessionId].playerConfig.name}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

const LeaderBoard = ({ players }) => {
  return (
    <div className="wr-round-result-view">
      <div className="wr-round-result-wrapper">
        <table className="table text-light">
          <thead>
            <tr>
              <th className="sticky-col wr-game-result-first-col"></th>
              <th className="wr-game-result-col">
                <strong>Total Points</strong>
              </th>
              <th className="wr-game-result-col">Rounds Won</th>
              <th className="wr-game-result-col">Winning Words</th>
              <th className="wr-game-result-col">Unique Words</th>
              <th className="wr-game-result-col">Non-Unique Words</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(players)
              .sort((a, b) =>
                players[a].playerStat.totalPoints >
                players[b].playerStat.totalPoints
                  ? -1
                  : 1
              )
              .map((sessionId, index) => (
                <tr key={index}>
                  <td className="sticky-col wr-game-result-first-col">
                    <strong className="margin-right-5">{index + 1}</strong>
                    <Avatar
                      avatar={players[sessionId].playerConfig.avatar}
                      avatarSize={32}
                      marginLeft={5}
                      marginRight={10}
                      display={"inline-block"}
                      verticalAlign={"middle"}
                    />
                    <span className="wr-leader-board-player-name">
                      {players[sessionId].playerConfig.name}
                    </span>
                  </td>
                  <td className="wr-game-result-col">
                    <strong>{players[sessionId].playerStat.totalPoints}</strong>
                  </td>
                  <td className="wr-game-result-col">
                    {players[sessionId].playerStat.roundsWon}
                  </td>
                  <td className="wr-game-result-col">
                    {players[sessionId].playerStat.numSoleGuesses}
                  </td>
                  <td className="wr-game-result-col">
                    {players[sessionId].playerStat.numUniqueGuesses}
                  </td>
                  <td className="wr-game-result-col">
                    {players[sessionId].playerStat.numNonUniqueGuesses}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

GameResults.defaultProps = {
  tourMode: false,
};

export default GameResults;
