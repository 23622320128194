import { React, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Joyride, { ACTIONS, EVENTS } from "react-joyride";
import { MDBIcon } from "mdb-react-ui-kit";

import CreateRoom from "./CreateRoom";
import Room from "./Room";

const Tour = ({ gameState, setGameState }) => {
  const DEBUG = process.env.REACT_APP_DEBUG || false;
  const navigate = useNavigate();

  // Tour config information
  const playerConfig = {
    sessionId: "1",
    name: "Jack",
    host: true,
    avatar: {
      backgroundColor: "#F8FBFF",
      bodyColor: "#6DBB58",
      bodyType: "BodyOval",
      eyeType: "EyesOpen",
      facialHairType: "Goatee",
      hairColor: "#5AC4D4",
      hairType: "Beanie",
      mouthType: "MouthSurprise",
      noseType: "NoseSmallround",
      skinColor: "#FFCC22",
    },
  };
  const baseGameState = {
    gameState: "WaitingRoom",
    players: {
      id1: {
        playerConfig: {
          avatar: {
            backgroundColor: "#F8FBFF",
            bodyColor: "#6DBB58",
            bodyType: "BodyOval",
            eyeType: "EyesOpen",
            facialHairType: "Goatee",
            hairColor: "#5AC4D4",
            hairType: "Beanie",
            mouthType: "MouthSurprise",
            noseType: "NoseSmallround",
            skinColor: "#FFCC22",
          },
          host: true,
          name: "Jack",
        },
        playerStat: {
          numNonUniqueGuesses: 0,
          numSoleGuesses: 0,
          numUniqueGuesses: 0,
          roundsWon: 0,
          totalPoints: 0,
        },
      },
    },
    roomConfig: {
      categories: [
        "fname",
        "lname",
        "city",
        "country",
        "animal",
        "job",
        "color",
        "car",
        "movie",
      ],
      hardMode: false,
      language: "en",
      lockRoom: false,
      numRounds: 3,
      roomName: "tour-sample-room",
      roundTimeLimit: 120,
      validateDictionary: true,
    },
    rounds: [],
    stateTimer: null,
  };
  const basePlayers = {
    id1: {
      playerConfig: {
        avatar: {
          backgroundColor: "#F8FBFF",
          bodyColor: "#6DBB58",
          bodyType: "BodyOval",
          eyeType: "EyesOpen",
          facialHairType: "Goatee",
          hairColor: "#5AC4D4",
          hairType: "Beanie",
          mouthType: "MouthSurprise",
          noseType: "NoseSmallround",
          skinColor: "#FFCC22",
        },
        host: true,
        name: "Jack",
      },
      playerStat: {
        numNonUniqueGuesses: 0,
        numSoleGuesses: 0,
        numUniqueGuesses: 0,
        roundsWon: 0,
        totalPoints: 0,
      },
    },
    id2: {
      playerConfig: {
        avatar: {
          backgroundColor: "#B379F7",
          bodyColor: "#E24553",
          bodyType: "BodyRound",
          eyeType: "EyesWink",
          facialHairType: "BeardMustache",
          hairColor: "#F29C65",
          hairType: "Buncurls",
          mouthType: "MouthSurprise",
          noseType: "NoseSmallround",
          skinColor: "#FBD2C7",
        },
        host: false,
        name: "Suzi",
      },
      playerStat: {
        numNonUniqueGuesses: 0,
        numSoleGuesses: 0,
        numUniqueGuesses: 0,
        roundsWon: 0,
        totalPoints: 0,
      },
    },
    id3: {
      playerConfig: {
        avatar: {
          backgroundColor: "#89E6E4",
          bodyColor: "#F55D81",
          bodyType: "BodyRound",
          eyeType: "EyesSleepy",
          facialHairType: "Nofacial",
          hairColor: "#362C47",
          hairType: "Bobbangs",
          mouthType: "MouthSmirk",
          noseType: "NoseMediumround",
          skinColor: "#FBD2C7",
        },
        host: false,
        name: "El",
      },
      playerStat: {
        numNonUniqueGuesses: 0,
        numSoleGuesses: 0,
        numUniqueGuesses: 0,
        roundsWon: 0,
        totalPoints: 0,
      },
    },
    id4: {
      playerConfig: {
        avatar: {
          backgroundColor: "#F8FBFF",
          bodyColor: "#F55D81",
          bodyType: "BodySquare",
          eyeType: "EyesWink",
          facialHairType: "Walrus",
          hairColor: "#6C4545",
          hairType: "Beanie",
          mouthType: "MouthSmirk",
          noseType: "NoseSmallround",
          skinColor: "#623D36",
        },
        host: false,
        name: "Clark",
      },
      playerStat: {
        numNonUniqueGuesses: 0,
        numSoleGuesses: 0,
        numUniqueGuesses: 0,
        roundsWon: 0,
        totalPoints: 0,
      },
    },
  };
  const baseRound = {
    categoryInputs: {
      animal: {
        id1: "cat",
        id2: "cat",
        id3: "cow",
        id4: "catt",
      },
      car: {
        id1: "chevrolet",
        id2: null,
        id3: "cadillac",
        id4: "chevrolet",
      },
      city: {
        id1: "cameroon",
        id2: null,
        id3: "chicago",
        id4: "chicagooo",
      },
      country: {
        id1: "colombia",
        id2: "china",
        id3: "china",
        id4: "china",
      },
      color: {
        id1: "cream",
        id2: "chocolate brown",
        id3: "coconut",
        id4: "caffff",
      },
      fname: {
        id1: "cleopatra",
        id2: "chris",
        id3: "christopher",
        id4: "clark",
      },
      job: {
        id1: null,
        id2: null,
        id3: "cook",
        id4: "carpenter",
      },
      lname: {
        id1: "carson",
        id2: "carson",
        id3: "chavez",
        id4: "clarkson",
      },
      movie: {
        id1: null,
        id2: null,
        id3: "cinderella",
        id4: null,
      },
    },
    categoryPoints: {
      animal: {
        id1: 5,
        id2: 5,
        id3: 10,
        id4: 0,
      },
      car: {
        id1: 5,
        id2: 0,
        id3: 10,
        id4: 5,
      },
      city: {
        id1: 0,
        id2: 0,
        id3: 20,
        id4: 0,
      },
      country: {
        id1: 10,
        id2: 5,
        id3: 5,
        id4: 5,
      },
      color: {
        id1: 10,
        id2: 10,
        id3: 10,
        id4: 0,
      },
      fname: {
        id1: 10,
        id2: 10,
        id3: 10,
        id4: 10,
      },
      job: {
        id1: 0,
        id2: 0,
        id3: 10,
        id4: 10,
      },
      lname: {
        id1: 5,
        id2: 5,
        id3: 10,
        id4: 10,
      },
      movie: {
        id1: 0,
        id2: 0,
        id3: 20,
        id4: 0,
      },
    },
    clueRegex: "^[cC].*$",
    clueString: 'Starting With "c"',
    numPlayers: 4,
  };

  // State of tour is controlled by these states
  // The number of step for the guided tour
  const [stepIndex, setStepIndex] = useState(0);
  // The view (create room or room) to show based on the step of tour
  const [activeTourComponent, setActiveTourComponent] = useState("CreateRoom");
  // Content of tour guide
  const steps = [
    {
      content: (
        <div>
          <h3>Let's learn how to play the game!</h3>We will give you a guided
          tour on how the game is played. We can start the game by creating a
          room for you and your friends. Click Next or the Enter/Return keyboard
          shortcut <MDBIcon fas border icon="right-to-bracket" /> to learn the
          game!
        </div>
      ),
      placement: "center",
      target: "body",
    },
    {
      content: (
        <div>
          <h3>Select an avatar for yourself</h3>Click on the avatar image to
          choose another random avatar.
        </div>
      ),
      target: ".avatar-selector",
    },
    {
      content: (
        <div>
          <h3>Select a nickname for yourself</h3>Select a unique nickname that
          represents you.
        </div>
      ),
      target: ".nickname-selector",
    },
    {
      content: (
        <div>
          <h3>Select language you'd like to play the game with</h3>WordRacer
          currently only supports the US English language.
        </div>
      ),
      target: ".language-selector",
    },
    {
      content: (
        <div>
          <h3>Optional: If you want to customize your game room</h3>
          You can customize the number of rounds, round time limits, validation
          of user inputs against a common dictionary, and the number of
          categories here.
        </div>
      ),
      target: ".advanced-options-selector",
    },
    {
      content: (
        <div>
          <h3>Create the game room</h3>
          Once you configured everything, click to create the room.
        </div>
      ),
      target: ".room-create-btn",
    },
    {
      content: (
        <div>
          <h3>We are now in the waiting room</h3>Once the room is created, we
          need to invite our friends to join the game.
        </div>
      ),
      placement: "center",
      target: "body",
    },
    {
      content: (
        <div>
          <h3>Copy and send the link to friends</h3>You can click on “Copy Link”
          and send it to your friends to join the game.
        </div>
      ),
      target: ".invite-selector",
    },
    {
      content: (
        <div>
          <h3>You can see you and the competitors here</h3>As you see your
          friends are joining the room they show up here, along with their game
          points throughout the game.
        </div>
      ),
      target: ".player-stat",
    },
    {
      content: (
        <div>
          <h3>Start the Game</h3>Once everyone joins, the host can start the
          game.
        </div>
      ),
      target: ".game-start-btn",
    },
    {
      content: (
        <div>
          <h3>Game has started, Time is ticking</h3>Now that the game started,
          you need to write down words as answers to the categories faster than
          anyone else.
        </div>
      ),
      placement: "center",
      target: "body",
    },
    {
      content: (
        <div>
          <h3>Look at the clue</h3>You need to write valid answers to all the
          categories based on the given clue in each round. Here, we need to
          write words starting with the letter "C".
        </div>
      ),
      target: ".round-clue",
    },
    {
      content: (
        <div>
          <h3>Here is where you need to write your answer</h3>This is the text
          field to write down the answer for your category given the clue. For
          example, here you need to write down a baby name starting with the
          letter "C". You can go to next incomplete category by hitting
          Enter/Return <MDBIcon fas border icon="right-to-bracket" /> keyboard
          shortcut.
        </div>
      ),
      target: ".category-text-field",
    },
    {
      content: (
        <div>
          <h3>Watch the remaining time</h3>This shows how much time left from
          the current round. If the timer reaches zero before you finish your
          inputs, or another player submits all inputs sooner, the round is
          over.
        </div>
      ),
      target: ".timer-div",
    },
    {
      content: (
        <div>
          <h3>This toolbar gives you ability to switch between categories</h3>
          This toolbar shows all the categories you need to fill in the game,
          their state, and buttons to switch between the categories. You click
          on icons to go to the respective category.
        </div>
      ),
      target: ".category-tooltip",
      placement: "left",
    },
    {
      content: (
        <div>
          <h3>The meaning of colors</h3>
          The current active category in your game is shown with navy color. The
          light blue color shows the categories you have filled but not
          validated by the game yet. You need to submit all results in order to
          find out if your input is valid or not. The green color shows the
          categories you have filled and are valid. The red color shows the
          categories you have filled but not a valid word.
        </div>
      ),
      target: ".category-button-selector",
      placement: "left",
    },
    {
      content: (
        <div>
          <h3>Go to next category</h3>
          Click on this button to go to the next category. You can also use the
          down keyboard shortcut <MDBIcon fas border icon="caret-down" /> for
          faster switching to the next category.
        </div>
      ),
      target: ".category-button-down",
      placement: "left",
    },
    {
      content: (
        <div>
          <h3>Go to previous category</h3>
          Click on this button to go to the previous category. You can also use
          the up arrow keyboard shortcut <MDBIcon
            fas
            border
            icon="caret-up"
          />{" "}
          for faster switching to the previous category.
        </div>
      ),
      target: ".category-button-up",
      placement: "left",
    },
    {
      content: (
        <div>
          <h3>Submit categories</h3>If you fill all categories before the time
          is up, you have the chance to finish the round by clicking this
          button. This is only available if all the categories are filled. You
          can use the Enter/Return keyboard shortcut{" "}
          <MDBIcon fas border icon="right-to-bracket" /> to switch between the
          non-filled categories, or submit the results if all the categories are
          filled. If the “Common Dictionary” option is enabled, the game will
          check the validity of your inputs.
        </div>
      ),
      target: ".round-submit-box",
    },
    {
      content: (
        <div>
          <h3>We are now in round result view</h3>Once the first player finishes
          or timer reaches the end, players will be routed to round result view
          to see inputs of each other and distribution of points for the round.
          There is a 30 second window to review the results with each other
          before the next round starts. We will explain how the pointing system
          works here.
        </div>
      ),
      placement: "center",
      target: "body",
    },
    {
      content: (
        <div>
          <h3>Unique words</h3>If any player comes up with a unique word for a
          certain category while others are also able to, they will each receive
          10 points.
        </div>
      ),
      target: ".tr-fname-round-result",
    },
    {
      content: (
        <div>
          <h3>Non-unique words</h3>If at least two players come up with the same
          word for a given category, then they will get only 5 points. The
          player who comes up with the unique word, would still get the complete
          10 points.
        </div>
      ),
      target: ".tr-country-round-result",
    },
    {
      content: (
        <div>
          <h3>Winning word</h3>If only one player in the competition is able to
          come up with a word for a given category while others are not, then
          the player would get a bonus 20 points. Note that if "Common word"
          validation is on for a game, words that are not available in the
          dictionary would be rejected. If a word has a misspelling, it will
          also be rejected.
        </div>
      ),
      target: ".tr-city-round-result",
    },
    {
      content: (
        <div>
          <h3>Total points for the round</h3>Here, you can see how much each
          competitor received their inputs in this round.
        </div>
      ),
      target: ".tr-points-round-result",
    },
    {
      content: (
        <div>
          <h3>Wait for the next round</h3>You have 30 seconds to review the
          results of the previous round, before the next round starts
          automatically. You'll play this game for the number of rounds the room
          is created with.
        </div>
      ),
      target: ".timer-div",
    },
    {
      content: (
        <div>
          <h3>Game Results</h3>Once you play all the rounds, you will see the
          game results page, with the leaderboard ranking.
        </div>
      ),
      placement: "center",
      target: "body",
    },
    {
      content: (
        <div>
          <h3>Trophies 🎉</h3>Once the game is finished you can see the top
          three players for the game here!
        </div>
      ),
      target: ".game-results-trophies",
    },
    {
      content: (
        <div>
          <h3>See Results</h3>You can see the details of each round's results,
          as well as the game leaderboard at the end of the game.
        </div>
      ),
      target: ".game-results-tabs",
    },
    {
      content: (
        <div>
          <h3>Leaderboard</h3>Leaderboard shows detailed statistics of each
          player.
        </div>
      ),
      target: ".game-results-tab-contents",
    },
    {
      content: (
        <div>
          <h3>That's It!</h3>This is all you need to know about this game! If
          you are ready to play, click to get started!
        </div>
      ),
      placement: "center",
      target: "body",
    },
  ];

  // This is the joyride main controller logic
  const handleJoyrideCallback = (data) => {
    const { action, index, lifecycle, type } = data;
    DEBUG && console.log(action, index, type, lifecycle);
    // Switch to the room view
    if (action === ACTIONS.NEXT && type === EVENTS.STEP_AFTER && index === 5) {
      DEBUG && console.log("Moving to Room component..");
      setGameState(baseGameState);
      setActiveTourComponent("Room");
    }
    // Switch back to the create room view if going backward
    else if (
      action === ACTIONS.PREV &&
      type === EVENTS.STEP_AFTER &&
      index === 6
    ) {
      DEBUG && console.log("Moving back to CreateRoom component..");
      setActiveTourComponent("CreateRoom");
    }
    // Waiting room with only host
    else if (action === ACTIONS.UPDATE && index >= 6 && index < 8) {
      setGameState(baseGameState);
    }
    // Waiting room with new players joined
    else if (
      (action === ACTIONS.UPDATE && index >= 8 && index < 10) ||
      (action === ACTIONS.PREV && type === EVENTS.STEP_AFTER && index === 10)
    ) {
      setGameState({
        ...baseGameState,
        players: basePlayers,
      });
    }
    // Game started
    else if (
      (action === ACTIONS.UPDATE && index >= 10 && index < 19) ||
      (action === ACTIONS.PREV && type === EVENTS.STEP_AFTER && index === 19)
    ) {
      setGameState({
        ...baseGameState,
        gameState: "RoundPlaying",
        players: basePlayers,
        rounds: [
          {
            categoryInputs: {},
            clueRegex: "^[cC].*$",
            clueString: 'Starting With "c"',
            numPlayers: 4,
          },
        ],
      });
    }
    // Round finished, reviewing round results
    else if (
      (action === ACTIONS.UPDATE && index >= 19 && index < 25) ||
      (action === ACTIONS.PREV && type === EVENTS.STEP_AFTER && index === 25)
    ) {
      setGameState({
        ...baseGameState,
        gameState: "RoundResults",
        players: {
          ...basePlayers,
          id1: {
            ...basePlayers.id1,
            playerStat: {
              numNonUniqueGuesses: 3,
              numSoleGuesses: 0,
              numUniqueGuesses: 2,
              roundsWon: 0,
              totalPoints: 45,
            },
          },
          id2: {
            ...basePlayers.id2,
            playerStat: {
              numNonUniqueGuesses: 3,
              numSoleGuesses: 0,
              numUniqueGuesses: 1,
              roundsWon: 0,
              totalPoints: 35,
            },
          },
          id3: {
            ...basePlayers.id3,
            playerStat: {
              numNonUniqueGuesses: 1,
              numSoleGuesses: 2,
              numUniqueGuesses: 5,
              roundsWon: 1,
              totalPoints: 105,
            },
          },
          id4: {
            ...basePlayers.id4,
            playerStat: {
              numNonUniqueGuesses: 2,
              numSoleGuesses: 0,
              numUniqueGuesses: 3,
              roundsWon: 0,
              totalPoints: 40,
            },
          },
        },
        rounds: [baseRound],
      });
    }
    // Game results
    else if (action === ACTIONS.UPDATE && index >= 25) {
      setGameState({
        ...baseGameState,
        gameState: "GameResults",
        players: {
          ...basePlayers,
          id1: {
            ...basePlayers.id1,
            playerStat: {
              numNonUniqueGuesses: 8,
              numSoleGuesses: 1,
              numUniqueGuesses: 5,
              roundsWon: 1,
              totalPoints: 125,
            },
          },
          id2: {
            ...basePlayers.id2,
            playerStat: {
              numNonUniqueGuesses: 7,
              numSoleGuesses: 0,
              numUniqueGuesses: 5,
              roundsWon: 0,
              totalPoints: 95,
            },
          },
          id3: {
            ...basePlayers.id3,
            playerStat: {
              numNonUniqueGuesses: 2,
              numSoleGuesses: 3,
              numUniqueGuesses: 11,
              roundsWon: 2,
              totalPoints: 180,
            },
          },
          id4: {
            ...basePlayers.id4,
            playerStat: {
              numNonUniqueGuesses: 4,
              numSoleGuesses: 0,
              numUniqueGuesses: 5,
              roundsWon: 0,
              totalPoints: 120,
            },
          },
        },
        rounds: [baseRound, baseRound, baseRound],
      });
    }
    // Tour finished
    else if (action === ACTIONS.RESET || type === EVENTS.TOUR_END) {
      DEBUG && console.log("Done with the tour, routing to the home page..");
      navigate("/");
    }
    // Update state to advance the tour regardless of above conditions
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    }
  };

  return (
    <>
      <Joyride
        continuous
        scrollToFirstStep
        showProgress
        showSkipButton
        hideCloseButton
        callback={handleJoyrideCallback}
        disableOverlayClose={true} // disable close on overlay click
        disableCloseOnEsc={true} // disable close on ESC key
        steps={steps}
        stepIndex={stepIndex}
        styles={{
          options: {
            primaryColor: "#3B71CA",
            zIndex: 1000,
          },
          buttonNext: {
            cursor: "pointer",
            padding: "10px",
            type: "button", // Set the type attribute to button
          },
        }}
        locale={{
          skip: <Link to="/">Close</Link>,
          last: (
            <Link to="/" style={{ color: "#FFF" }}>
              I'm ready to play
            </Link>
          ),
        }}
      />
      {activeTourComponent === "CreateRoom" && (
        <CreateRoom
          sendMessage={null}
          lastMessage={null}
          playerConfig={playerConfig}
          setPlayerConfig={null}
          generateRandomAvatar={null}
          tourMode={true}
        />
      )}
      {activeTourComponent === "Room" && (
        <Room
          sendMessage={null}
          lastMessage={null}
          playerConfig={playerConfig}
          setPlayerConfig={null}
          gameState={gameState}
          setGameState={setGameState}
          tourMode={true}
        />
      )}
    </>
  );
};

export default Tour;
