import { React, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./css/App.css";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Room from "./components/Room";
import CreateRoom from "./components/CreateRoom";
import JoinRoom from "./components/JoinRoom";
import * as metadata from "./components/Metadata";
import Terms from "./components/Terms";
import Tour from "./components/Tour";
import WebsocketOutlet from "./components/WebsocketOutlet";

function App() {
  // TODO move these config to GameLayout for better organization
  const generateRandomAvatar = () => {
    const avatarConfig = {
      skinColor:
        metadata.avatarMetadata.skinColors[
          Math.floor(Math.random() * metadata.avatarMetadata.skinColors.length)
        ],
      hairColor:
        metadata.avatarMetadata.hairColors[
          Math.floor(Math.random() * metadata.avatarMetadata.hairColors.length)
        ],
      bodyColor:
        metadata.avatarMetadata.bodyColors[
          Math.floor(Math.random() * metadata.avatarMetadata.bodyColors.length)
        ],
      backgroundColor:
        metadata.avatarMetadata.backgroundColors[
          Math.floor(
            Math.random() * metadata.avatarMetadata.backgroundColors.length
          )
        ],
      facialHairType:
        metadata.avatarMetadata.facialHairTypes[
          Math.floor(
            Math.random() * metadata.avatarMetadata.facialHairTypes.length
          )
        ],
      eyeType:
        metadata.avatarMetadata.eyesTypes[
          Math.floor(Math.random() * metadata.avatarMetadata.eyesTypes.length)
        ],
      mouthType:
        metadata.avatarMetadata.mouthTypes[
          Math.floor(Math.random() * metadata.avatarMetadata.mouthTypes.length)
        ],
      noseType:
        metadata.avatarMetadata.noseTypes[
          Math.floor(Math.random() * metadata.avatarMetadata.noseTypes.length)
        ],
      bodyType:
        metadata.avatarMetadata.bodyTypes[
          Math.floor(Math.random() * metadata.avatarMetadata.bodyTypes.length)
        ],
      hairType:
        metadata.avatarMetadata.hairTypes[
          Math.floor(Math.random() * metadata.avatarMetadata.hairTypes.length)
        ],
    };
    return avatarConfig;
  };

  const [playerConfig, setPlayerConfig] = useState({
    sessionId: null,
    name: null,
    host: null,
    avatar: generateRandomAvatar(),
  });

  const [gameState, setGameState] = useState({
    gameState: "WaitingRoom",
    roomConfig: null,
    stateTimer: null,
    players: {},
    rounds: [],
  });

  return (
    <div className="bg-gradient-blue">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route element={<WebsocketOutlet />}>
            <Route
              path="create-room"
              element={
                <CreateRoom
                  playerConfig={playerConfig}
                  setPlayerConfig={setPlayerConfig}
                  generateRandomAvatar={generateRandomAvatar}
                />
              }
            />
            <Route
              path="join-room/:id"
              element={
                <JoinRoom
                  playerConfig={playerConfig}
                  setPlayerConfig={setPlayerConfig}
                  generateRandomAvatar={generateRandomAvatar}
                />
              }
            />
            <Route
              path="room/:id"
              element={
                <Room
                  playerConfig={playerConfig}
                  setPlayerConfig={setPlayerConfig}
                  gameState={gameState}
                  setGameState={setGameState}
                />
              }
            />
          </Route>
          <Route
            path="/tour"
            element={<Tour gameState={gameState} setGameState={setGameState} />}
          />
          <Route path="/terms" element={<Terms />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
