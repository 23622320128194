import { React } from "react";
import { MDBIcon } from "mdb-react-ui-kit";
import Avatar from "./Avatar";
import * as metadata from "./Metadata";

const RoundResult = ({ round, playerConfig, gameState, tourMode }) => {
  return (
    <div className="wr-round-result-view">
      <div className="wr-round-result-wrapper">
        {/* Work out a way for users to give feedback on dictionary check */}
        {/* {gameState.roomConfig.validateDictionary && (
          <div className="margin-bottom-5">
            Your inputs are valid but not been accepted? Click{" "}
            <a
              className="text-light"
              href="#"
              style={{ textDecoration: "underline !important" }}
            >
              here
            </a>{" "}
            to let us know so we can improve our database.
          </div>
        )} */}
        <table className="table text-light">
          <thead>
            <tr>
              <th className="sticky-col wr-round-result-first-col">
                {round.clueString}
              </th>
              {!tourMode && (
                <th>
                  <TableHeader
                    key={playerConfig.sessionId}
                    name={playerConfig.name}
                    avatar={playerConfig.avatar}
                  />
                </th>
              )}
              {Object.keys(gameState.players)
                .filter((sessionId) => sessionId !== playerConfig.sessionId)
                .map((sessionId) => {
                  return (
                    <th key={sessionId}>
                      <TableHeader
                        name={gameState.players[sessionId].playerConfig.name}
                        avatar={
                          gameState.players[sessionId].playerConfig.avatar
                        }
                      />
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tfoot>
            <tr className="tr-points-round-result">
              <td className="sticky-col wr-round-result-first-col">
                <MDBIcon icon="medal" />
                <span className="wr-round-result-first-col-label">
                  {" Sum"}
                </span>
              </td>
              {!tourMode && (
                <td className="wr-round-result-col">
                  <h5>
                    <span className="badge bg-danger">
                      +
                      {Object.values(round.categoryPoints).reduce(
                        (acc, val) => acc + (val[playerConfig.sessionId] || 0),
                        0
                      )}
                    </span>
                  </h5>
                </td>
              )}
              {Object.keys(gameState.players)
                .filter((sessionId) => sessionId !== playerConfig.sessionId)
                .map((sessionId) => {
                  return (
                    <td key={sessionId} className="wr-round-result-col">
                      <h5>
                        <span className="badge bg-danger">
                          +
                          {Object.values(round.categoryPoints).reduce(
                            (acc, val) => acc + (val[sessionId] || 0),
                            0
                          )}
                        </span>
                      </h5>
                    </td>
                  );
                })}
            </tr>
          </tfoot>
          <tbody>
            {gameState.roomConfig.categories.map((categoryId, index) => {
              return (
                <tr
                  key={index}
                  className={"tr-" + categoryId + "-round-result"}
                >
                  <td className="sticky-col wr-round-result-first-col">
                    <MDBIcon
                      icon={metadata.categoryMetadata[categoryId].icon}
                    />
                    <span className="wr-round-result-first-col-label">
                      {" " + metadata.categoryMetadata[categoryId].name}
                    </span>
                  </td>
                  {!tourMode && (
                    <td className="wr-round-result-col">
                      <TableCell
                        categoryInput={
                          round.categoryInputs[categoryId][
                            playerConfig.sessionId
                          ]
                        }
                        points={
                          round.categoryPoints[categoryId][
                            playerConfig.sessionId
                          ]
                        }
                      />
                    </td>
                  )}
                  {Object.keys(gameState.players)
                    .filter((sessionId) => sessionId !== playerConfig.sessionId)
                    .map((sessionId) => {
                      return (
                        <td key={sessionId} className="wr-round-result-col">
                          <TableCell
                            categoryInput={
                              round.categoryInputs[categoryId][sessionId]
                            }
                            points={round.categoryPoints[categoryId][sessionId]}
                          />
                        </td>
                      );
                    })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const TableHeader = ({ name, avatar }) => {
  // Logic how to fill the table header
  return (
    <>
      <Avatar
        avatar={avatar}
        avatarSize={32}
        marginRight={10}
        display={"inline-block"}
        verticalAlign={"middle"}
      />
      {name}
    </>
  );
};

const TableCell = ({ categoryInput, points }) => {
  // Logic how to fill the table cell value
  let badgeClass;
  if (points === 20) {
    badgeClass = "badge bg-primary";
  } else if (points === 10) {
    badgeClass = "badge bg-warning";
  } else if (points === 5) {
    badgeClass = "badge bg-secondary";
  } else {
    badgeClass = "badge bg-secondary";
  }

  return (
    <>
      {points > 0 ? <span className={badgeClass}>+{points}</span> : ""}
      <span
        className={
          categoryInput && points === 0
            ? "margin-left-5 wr-incorrect-answer"
            : "margin-left-5"
        }
      >
        {categoryInput}
      </span>
    </>
  );
};

RoundResult.defaultProps = {
  tourMode: false,
};

export default RoundResult;
