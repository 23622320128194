import React, { useCallback, createContext, useContext } from "react";
import useWebSocket from "react-use-websocket";

const WebSocketContext = createContext();

const WebSocketProvider = ({ children }) => {
  // Resolve configs
  const webSocketUri =
    process.env.REACT_APP_WEBSOCKET_URL ||
    "wss://ktjq8vnvxh.execute-api.us-east-2.amazonaws.com/production";
  const DEBUG = process.env.REACT_APP_DEBUG || false;

  const getSocketUrl = useCallback(() => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(webSocketUri);
      }, 2000);
    });
  }, [webSocketUri]);

  const { sendJsonMessage, lastJsonMessage } = useWebSocket(getSocketUrl, {
    onOpen: () => {
      DEBUG &&
        console.log(new Date().toLocaleString() + ": Connected to websocket!");
      let params = new URL(document.location).searchParams;
      let statusCode = params.get("statusCode");
      if (statusCode === "408") {
        window.location.href = "/";
      }
    },
    onMessage: (e) => {
      try {
        const log = JSON.parse(e.data);
        log.recieptDateTime = new Date().toLocaleString();
        DEBUG && console.log(log);
        if (!DEBUG && log.message === "Internal server error") {
          window.location.href = "/?statusCode=500";
        }
      } catch (err) {
        DEBUG && console.log(new Date().toLocaleString() + ": " + e);
      }
    },
    onClose: (closeEvent) => {
      DEBUG && console.log(closeEvent);
      if (DEBUG) {
        console.log(new Date().toLocaleString() + ": Websocket Closed");
      } else {
        window.location.href = "/?statusCode=" + (closeEvent.code || "410");
      }
    },
    shouldReconnect: (closeEvent) => {
      return false;
    },
  });

  return (
    <WebSocketContext.Provider value={{ sendJsonMessage, lastJsonMessage }}>
      {children}
    </WebSocketContext.Provider>
  );
};

const useWebSocketContext = (tourMode) => {
  const contextData = useContext(WebSocketContext);
  if (tourMode) {
    return [null, null];
  } else {
    return contextData;
  }
};

export { WebSocketProvider, useWebSocketContext };
